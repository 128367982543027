export const environment = {
  firebase: {
    apiKey: "AIzaSyDapgYzQXQ6Y5VrFL1L4r24PKqsvJtAmxU",
    authDomain: "pro-tasking.firebaseapp.com",
    projectId: "pro-tasking",
    storageBucket: "pro-tasking.appspot.com",
    messagingSenderId: "880345708490",
    appId: "1:880345708490:web:b3868e2872f1344597c133",
    measurementId: "G-CWSVHNT3G0"
  },
  production: true
};

